<template>
  <div>
    <d-body-top-bar
      :title="$t('views.client.import.title')"
      :is-icons-content="false"
    >
      <div slot="tree">
        <label @click="$router.push({ name: 'clients_list' })" class="inactive-tree link-area pointer">
          {{ $t('views.client.all-customers') }} /
        </label>
        <label class="bg-doinsport-primary ml-1"> {{ $t('views.client.import.title') }} </label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <b-row>
        <b-col cols="12">
          <b-card class="container-flex">
            <steps
              :step="step"
              @on:step-0:selected="step = 0"
              @on:step-1:selected="step = 1"
              @on:step-2:selected="step = 2"
              @on:prev-step="prevStep"
              @on:next-step="nextStep"
            />
            <step0
              v-if="step === 0"
              :uploading="uploading"
              @on:new-file="newFile"
            />
            <step1
              v-else-if="step === 1"
              :table-fields="tableFields"
              :items="items"
            />
            <step2
              v-else-if="step === 2"
              :table-fields="tableFields"
              :items="items"
              :subscriptions="subscriptions"
              :tokens="tokens"
              @on:prev-step="prevStep"
              @on:next-step="nextStep2"
            />
            <step3
              v-else-if="step === 3"
              :subscriptions="subscriptions"
              :tokens="tokens"
              :items="items"
              :importing="importing"
              @on:click-import="importCustomers"
            />
          </b-card>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import { postClubClientImport } from '@api/doinsport/services/club/club-client-imports.api'
import {SUCCESS} from "@plugins/flash";

export default {
  components: {
    Steps: () => import('./steps/Menus'),
    Step0: () => import('./steps/step-0/Index'),
    Step1: () => import('./steps/step-1/Index'),
    Step2: () => import('./steps/step-2/Index'),
    Step3: () => import('./steps/step-3/Index'),
  },
  data: () => ({
    step: 0,
    skipStep2: false,
    file: null,
    tableFields: [],
    items: [],
    subscriptions: [],
    tokens: [],
    uploading: false,
    importing: false,
  }),
  computed: {
  },
  methods: {
    newFile(file) {
      this.uploading = true

      this.file = file;

      this.tableFields = [];
      this.items = [];

      let reader = new FileReader();
      reader.onload = (e) => {
        let rows = e.target.result.split("\r\n");
        let firstRow = [];
        rows.forEach((row, i) => {
          if (i === 0) {
            firstRow = row.split(';');
            firstRow.forEach(el => {
              this.tableFields.push({
                key: el,
                label: el,
              });
            })
            return;
          }
          let rowObject = {}
          row.split(';').forEach((el, idx) => {
            rowObject[firstRow[idx]] = el;
          })
          this.items.push(rowObject);
        })
        this.step = 1;
        this.uploading = false;
      }
      reader.readAsText(file);
    },
    prevStep() {
      if (this.step === 0) {
        return;
      } else if (this.step === 3 && this.skipStep2) {
        this.step = 1;
        return;
      }
      this.step -= 1;
    },
    nextStep() {
      if (this.step === 3) {
        return;
      }
      this.step += 1;
    },
    nextStep2(subscriptions, tokens, skip) {
      this.subscriptions = subscriptions;
      this.tokens = tokens;
      this.skipStep2 = skip;
      this.step = 3;
    },
    importCustomers() {
      this.importing = true;
      let subscriptionPlanPriceMatch = {}
      let paymentTokenMatch = {}
      this.subscriptions.forEach((sub) => {
        subscriptionPlanPriceMatch[sub.key] = sub.assigned
      })
      this.tokens.forEach((token) => {
        paymentTokenMatch[token.key] = token.assigned
      })

      const payload = {
        url: null,
        target: 'postClubClientImportFile'
      };
      this.$uploadFile(this.file, payload)
        .then((response) => {
          postClubClientImport({
            file: response['@id'],
            subscriptionPlanPriceMatch,
            paymentTokenMatch
          }).then((response) => {
            this.$flash(null, this.$t('views.client.import.inprogress'), 4000, SUCCESS);
            this.$router.push({name: 'clients_list'});
            this.importing = false
          })
        });
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@lazy/client/import/_client.scss";
</style>
